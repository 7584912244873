<template>
  <div>
    <h3>Профиль пользователя {{ user.fio_full }}</h3>
    <!--        <div class="form-group">
            <label class=" control-label">Внешний вид</label>
            <div >
                <div class="btn-group">
                    <button type="button" class="btn btn-default btn-sm dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        {{user.tpl_name}} <span class="caret"></span>
                    </button>
                    <ul class="dropdown-menu">
                        <li v-for="ttt in tpl" @click="saveTpl(ttt)"><a href="#">{{ttt.name}}</a></li>
                    </ul>
                </div>
            </div>
        </div>-->
    <v-form v-model="valid" lazy-validation>
      <v-layout wrap>
        <v-flex xs12 sm4>
          <v-text-field
            v-model="user.sur"
            label="Фамилия"
            required
            :rules="[(v) => !!v || 'Поле должно быть заполнено']"
          ></v-text-field>
        </v-flex>
        <v-flex xs12 sm4>
          <v-text-field
            v-model="user.name"
            label="Имя"
            required
            :rules="[(v) => !!v || 'Поле должно быть заполнено']"
          ></v-text-field>
        </v-flex>
        <v-flex xs12 sm4>
          <v-text-field
            v-model="user.patronymic"
            label="Отчество"
          ></v-text-field>
        </v-flex>
        <v-flex xs12 sm6>
          <v-text-field v-model="user.phone" label="Телефон"></v-text-field>
        </v-flex>
        <v-flex xs12 sm6>
          <v-text-field v-model="user.email" label="E-mail"></v-text-field>
        </v-flex>
        <v-flex xs12 sm6>
          <v-text-field
            v-model="user.login"
            label="Логин"
            required
            :rules="[(v) => !!v || 'Поле должно быть заполнено']"
          ></v-text-field>
        </v-flex>
        <v-flex xs12 sm6>
          <v-text-field v-model="user.pass" label="Новый пароль"></v-text-field>
        </v-flex>
      </v-layout>
    </v-form>

    <v-btn color="primary" dark @click.native="save">Сохранить</v-btn>
  </div>
</template>

<script>
export default {
  data() {
    return {
      user: [],
      errSur: '',
      errName: '',
      errLogin: '',
      valid: false,
    }
  },
  computed: {
    tpl: function () {
      return this.$store.state.spr.tpl
    },
  },
  components: {},
  mounted: function () {
    this.dataLoad()
    this.$store.dispatch('loadTpl')
  },
  methods: {
    saveTpl: function (tpl) {
      var t = this
      this.$http
        .post(this.$store.state.apiUrl + 'user/savetpl/', {
          tpl: tpl.id,
        })
        .then(
          (response) => {
            t.$store.dispatch('setMessage', {
              type: response.body.err == 1 ? 'error' : 'success',
              message: response.body.msg,
            })
            if (!response.body.err) {
              t.user.tpl_name = tpl.name
              t.$store.commit('setTpl', tpl.id)
            }
          },
          (err) => {
            console.log(err)
          }
        )
    },
    dataLoad: function () {
      var t = this
      this.$http.post(this.$store.state.apiUrl + 'user/profile').then(
        (response) => {
          if (response.body.err > 0) {
            console.log(response.body)
          } else {
            t.user = response.body
            console.log(t.user)
          }
        },
        (err) => {
          console.log(err)
        }
      )
    },
    save: function () {
      var t = this
      var arr = Object.assign({}, this.user)

      if (t.valid) {
        this.$http
          .post(this.$store.state.apiUrl + 'user/psave/', {
            user: arr,
          })
          .then(
            (response) => {
              t.$store.dispatch('setMessage', {
                type: response.body.err == 1 ? 'error' : 'success',
                message: response.body.msg,
              })
              t.$store.commit('setUserfio', response.body.fio)
            },
            (err) => {
              console.log(err)
            }
          )
      }
    },
  },
}
</script>

<style></style>
